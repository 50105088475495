<template>
  <IonPage>
    <IonContent>
      <div class="flex flex-col justify-center items-center text-center h-full">
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Зөвхөн бүртгүүлсэн хэрэглэгчдэд нээлттэй.</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <p class="indent-1">
             Та манай сайтад бүртгүүлснээр өөрийн дуртай, сурч буй болон сурахыг хүсэж буй дуугаа ангилан хадгалах боломжтой "Миний туслах цэс"-ийг ашиглах эрхтэй болно.
            </p>
          </IonCardContent>
        </IonCard>
        <div class="grid grid-cols-2 mt-8 gap-12 text-center">
          <div>
            <IonButton fill="outline" router-link="#signin"> Нэвтрэх </IonButton>
          </div>
          <div>
            <IonButton router-link="#signup"> Бүртгүүлэх </IonButton>
          </div>
        </div>
      </div>
    </IonContent>
  </IonPage>
</template>
