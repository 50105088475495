<template>
  <IonHeader translucent>
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton text="" default-href="/" />
      </IonButtons>
    </IonToolbar>
  </IonHeader>
  <IonContent>
    <BlankSlate
      icon="offline"
      title="Офлайнаар ашиглах боломжгүй"
      description="Манай сайт тун удахгүй офлайн горим дэмждэг болно."
    />
  </IonContent>
</template>
